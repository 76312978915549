import { useState,useEffect } from "react";
import { ReactComponent as Logo } from "./assets/MOSLOGO.svg";
import { ReactComponent as SpeakBtn } from "./assets/Sign lang 2.svg";
import GenericModal from "./components/GenericModal";

export default function App() {
  const [openIframe, setopenIframe] = useState(false);
  const [openModal, setopenModal] = useState(false);

  const MyIcon = () => {
    return (
      <svg
        className="opacity-80"
        height="64px"
        width="64px"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{ fill: '#4E4E50' }}
      >
        <g id="Layer_1" />
        <g id="play_x5F_alt">
          <path d="M16,0C7.164,0,0,7.164,0,16s7.164,16,16,16s16-7.164,16-16S24.836,0,16,0z M10,24V8l16.008,8L10,24z" />
        </g>
      </svg>
    );
  };


  return (
    <div className="h-screen bg-white">
      <div className="max-w-[80rem] mx-5 lg:mx-auto z-50  ">
        <div className="flex justify-end py-7 ">
          <Logo className="w-40" />
        </div>

        {openIframe ? (
            <div className="flex justify-center items-center h-[80vh]">
            <iframe
              src="https://omnicx.flitc.ai/chat_plugin/index.php?token=c9f7142adf794c3d4f5b763da16891e3"
              className="h-full w-[full]  max-h-[58vh] max-w-[1200px]  flex justify-center items-center "
              allow="camera; microphone; fullscreen; autoplay; encrypted-media; geolocation"
              style={{ border: "none" }}
            />
          </div>
        
    // <iframe
    //   src="https://omnicx.flitc.ai/chat_plugin/index.php?token=c9f7142adf794c3d4f5b763da16891e3"
    //   className="h-full w-full  flex justify-center items-center" // You can limit the width if needed
    //   allow="camera; microphone; fullscreen; autoplay; encrypted-media; geolocation"
    // />
        ) : (
          <div className="flex justify-center items-center min-h-[75vh] ">
            <div dir="rtl" className="flex flex-col lg:flex-row">
              <div>
                <div className="text-right space-y-5 lg:p-10 ">
                  <h1 className="text-[#079D3D] font-medium text-3xl mb-5 ">
                    مرحباً بكم في موقعasd
                    <br /> وزارة الرياضة
                  </h1>
                  <h1 className="font-medium text-lg mb-5 ">
                    هذه الخدمة مخصصة للصم وضعاف السمع.
                  </h1>
                  <p className="font-light text-md leading-loose ">
                    يرجى الضغط أدناه على زر 
                    <span className="font-bold"> "ابدأ المكالمة الان" </span>
                    لنتمكن من خدمتك بأفضل طريقة ممكنة. الخدمة متوفرة خلال أوقات العمل من الأحد إلى الخميس، من الساعة 10 صباحاً إلى الساعة 6 مساءً.
                  </p>
                </div>

                <div className="flex justify-center lg:justify-start my-5 lg:mx-5 lg:my-0 mx-auto">
                  <SpeakBtn
                    onClick={() => setopenIframe(true)}
                    className="h-12 w-52 cursor-pointer"
                  />
                </div>
              </div>

              <div
                className="flex items-start justify-center"
                onClick={() => {
                  setopenModal(true);
                }}
              >
                <div className="relative w-full h-full">
                  <video
                    muted
                    className="lg:p-1 w-full h-full object-cover rounded-3xl"
                  >
                    <source src="./MOS - Vd.02.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>

                  <div className="absolute inset-0 flex justify-center items-center">
                    <MyIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <GenericModal open={openModal} setOpen={setopenModal}>
        <video
          muted="true"
          autoPlay
          controls
          className="w-full h-full object-cover "
        >
          <source src="./MOS - Vd.02.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </GenericModal>
    </div>
  );
}

